<template>
    <div class="d-flex justify-content-center align-items-center vh-100">
        <div class="col-lg-5 mx-auto">
            <div class="card border-0">
                <div class="card-body">
                    <div class="ex-page-content text-center pb-4">
                        <h4 class="mt-3 mb-1 font-weight-semibold font-18">The page you are looking for is</h4>
                        <h1 style="font-size: 4em" class="mt-5 mb-4">Under Development</h1>
                    </div>
                    <router-link to="/" class="btn btn-primary btn-block waves-effect waves-light">Back to Home <i class="fas fa-redo ml-1"></i></router-link>
                </div>
            </div><!--end card-->
        </div>
    </div>
</template>

<script>
export default {
    name: "underDevelopement",
}
</script>

<style scoped>

</style>